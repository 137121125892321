import Icon from "@hyperlocal/vital-icons";
import styled, { StyledComponent } from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const Container = styled.div(({ theme: { spacings } }) => ({
  maxWidth: "432px",

  [EBreakPoints.mobile]: {
    width: "auto",

    padding: spacings.inset.md,
  },
}));

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Date = styled.time(({ theme: { fontWeights, fontSizes } }) => ({
  fontWeight: fontWeights.bold,
  fontSize: fontSizes.sm,
}));

export const Actions = styled.div(({ theme: { spacings } }) => ({
  display: "flex",
  gap: spacings.inline.x2s,
}));

export const ActionIcon = styled(Icon as StyledComponent<"div", any, {}>)(
  ({ theme: { palette } }) => ({
    fill: palette.neutral.darkest,
    cursor: "pointer",
  }),
);
