import { ComponentProps, useEffect, useMemo } from "react";
import {
  getReceivedRoutesData,
  Hooks,
  setHeader,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, TextField } from "@hyperlocal/vital2";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { AnticipationFiltersFields } from "./filters-fields";

type AnticipationFiltersProps = ComponentProps<"div">;

const FILTERS = ["status", "initialDate", "endDate"];

export const AnticipationFilters = ({
  className,
  ...props
}: AnticipationFiltersProps) => {
  const routes = getReceivedRoutesData();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = Hooks.useMediaQuery("mobile");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClearFilters = () => {
    setSearchParams((params) => {
      FILTERS.forEach((key) => params.delete(key));

      return params;
    });
  };

  const hasFilters = useMemo(() => {
    return FILTERS.some((key) => searchParams.get(key));
  }, [searchParams]);

  useEffect(() => {
    isMobile &&
      setHeader({
        title: "Filtrar antecipação",
        rightIcon: {
          name: "GeralClose",
          onClick: () => navigate(routes.futureReceiptsAnticipation),
        },
      });
    return () => {
      setHeader({});
    };
  }, [isMobile, navigate, routes]);

  return (
    <div
      className={twMerge(
        "space-y-6 flex flex-col h-full mobile:min-h-[calc(100dvh-56px)] justify-between",
        className,
      )}
      {...props}
    >
      <div className="space-y-6">
        <AnticipationFiltersFields.Range />
        {/* AINDA NÃO TEM NO BACK */}
        <TextField.Root>
          <TextField.Input placeholder="Buscar..." disabled />
          <TextField.Slot name="SearchNormal" />
        </TextField.Root>

        <AnticipationFiltersFields.Dates />
        <AnticipationFiltersFields.Status />
      </div>

      <div className="flex flex-col gap-2 w-full">
        <Button.Root
          disabled={!hasFilters}
          className="desktop:hidden"
          onClick={() =>
            navigate({
              pathname: getReceivedRoutesData().futureReceiptsAnticipation,
              search: location.search,
            })
          }
        >
          Filtrar
        </Button.Root>

        <Button.Root
          variant="link"
          disabled={!hasFilters}
          onClick={() => handleClearFilters()}
        >
          Limpar filtro
        </Button.Root>
      </div>
    </div>
  );
};
