import { useCheckAppVersion } from "../api/use-check-app-version";
import { FutureReceiptsRoutes } from "../features/futureReceipts/routes";
import { ReceivedRoutes } from "../features/receivedValues/routes";

export const Routes = () => {
  useCheckAppVersion();
  return (
    <>
      <ReceivedRoutes />
      <FutureReceiptsRoutes />
    </>
  );
};
