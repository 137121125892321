import { ComponentProps, ReactNode } from "react";
import {
  Chips,
  Avatar as VitalAvatar,
  Breadcrumb as vitalBreadcrumb,
} from "@hyperlocal/vital";
import styled, { StyledComponent } from "styled-components";
import { EBreakPoints } from "../../@types";
import { BREAKPOINTS } from "../../config";

export const Container = styled.div((props) => ({
  display: "flex",
  background: props.theme.palette.neutral.lighter,
  minHeight: "calc(100svh - 56px)",

  [EBreakPoints.mobile]: {
    width: "100vw",
    height: "100%",
    minHeight: "unset",
    padding: 0,
    background: props.theme.palette.neutral.white,
  },
}));

export const Breadcrumb = styled(
  vitalBreadcrumb as StyledComponent<"div", any, {}>,
)({
  [EBreakPoints.mobile]: {
    display: "none",
  },
});

export const Content = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",

  width: "100%",
  maxWidth: 1248,

  margin: "24px auto",
  padding: "0 64px",
  background: props.theme.palette.neutral.lighter,

  [BREAKPOINTS.mobile]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 0,
    background: props.theme.palette.neutral.white,
  },
}));

interface BoxProps {
  children?: ReactNode;
}

export const Box = styled.div<BoxProps>((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: props.theme.spacings.inline.sm,

  [EBreakPoints.mobile]: {
    gap: 0,
  },
}));

export const Title = styled.h3((props) => ({
  fontFamily: props.theme.fonts.base,
  fontStyle: "normal",
  fontWeight: props.theme.fontWeights.bold,
  fontSize: props.theme.fontSizes.lg,
  lineHeight: props.theme.lineHeights.default,
  color: props.theme.palette.neutral.darkest,

  [EBreakPoints.mobile]: {
    display: "none",
  },

  display: "flex",
  gap: "8px",
  alignItems: "center",
}));

export const Filters = styled.div((props) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  minWidth: 557,
  width: 557,
  height: 784,

  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.lighter}`,

  backgroundColor: props.theme.palette.neutral.white,

  padding: props.theme.spacings.stack.x2s,

  [EBreakPoints.mobile]: {
    width: "100%",
    height: "100%",
    minWidth: "100vw",

    border: 0,

    position: "initial",

    padding: props.theme.spacings.stack.x3s,
  },
}));

export const Display = styled.div({
  height: 784,
  width: "100vh",
  minWidth: 557,

  [EBreakPoints.mobile]: {
    width: "100vw",
    height: "100%",
    minWidth: "100vw",
    padding: 0,
  },
});

export const Card = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",

  padding: `${props.theme.spacings.stack.x3s} ${props.theme.spacings.stack.x2s}}`,

  height: 96,
  width: 644,

  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.white}`,

  backgroundColor: props.theme.palette.neutral.white,

  marginBottom: props.theme.spacings.stack.x2s,

  [EBreakPoints.mobile]: {
    width: "100vw",
    height: 72,

    padding: `0 ${props.theme.spacings.stack.x3s}`,
    margin: 0,
  },
}));

export const CardContent = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const CardInfo = styled.div((props) => ({
  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.default,
    color: props.theme.palette.neutral.darkest,
  },

  span: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.medium,
    fontSize: props.theme.fontSizes.x2s,
    color: props.theme.palette.neutral.dark,
    lineHeight: props.theme.lineHeights.default,
  },
}));

export const CardValue = styled.div((props) => ({
  display: "flex",
  alignItems: "center",

  h4: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.md,
    lineHeight: props.theme.lineHeights.md,
    textAlign: "right",

    color: props.theme.palette.primary.main,

    paddingBottom: 1,
    paddingLeft: 4,
  },
  h6: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.default,
    textAlign: "right",

    color: props.theme.palette.primary.main,
  },
}));

type AvatarProps = ComponentProps<typeof VitalAvatar>;

export const Avatar: typeof VitalAvatar = styled(VitalAvatar)<AvatarProps>(
  (props) => ({
    backgroundColor: props.theme.palette.primary.lighter,

    cursor: "initial",

    width: 44,
    height: 44,

    marginRight: 16,

    "& svg": {
      width: 17.42,
      height: 17.42,
      "& path": {
        fill: props.theme.palette.primary.main,
      },
      "& g": {
        stroke: props.theme.palette.primary.main,
      },
    },
  }),
);

export const ListContainer = styled.div((props) => ({
  width: 644,
  height: 664,

  padding: `${props.theme.spacings.stack.x3s} ${props.theme.spacings.stack.x2s}`,

  display: "flex",
  flexDirection: "column",

  backgroundColor: props.theme.palette.neutral.white,

  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.lighter}`,

  [EBreakPoints.mobile]: {
    padding: `0 ${props.theme.spacings.stack.x3s}`,
    width: "100vw",
    border: 0,
  },
}));

export const List = styled.ul((props) => ({
  width: "100%",
  height: "100%",

  overflowX: "auto",

  li: {
    "& + li": {
      marginTop: props.theme.spacings.stack.x3s,
    },
  },
}));

export const ListHeadings = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  marginBottom: props.theme.spacings.stack.x3s,
  marginTop: props.theme.spacings.stack.x3s,

  h5: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.sm,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
  },

  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.sm,
    color: props.theme.palette.neutral.dark,
  },

  [EBreakPoints.mobile]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
    h5: {
      fontSize: props.theme.fontSizes.xs,
    },

    p: {
      fontWeight: props.theme.fontWeights.medium,
      fontSize: props.theme.fontSizes.x3s,
    },
  },
}));

export const ListItem = styled.li((props) => ({
  listStyleType: "none",

  justifyContent: "space-between",
  alignItems: "center",

  padding: "12px 24px",
  gap: 16,

  width: "100%",
  height: 73,

  border: `1px solid ${props.theme.palette.neutral.light}`,
  borderRadius: props.theme.borderRadius.sm,
  "&:hover": {
    border: `1px solid ${props.theme.palette.primary.main}`,
    transition: "border-color 300ms linear 0s",
  },

  "& svg": {
    display: "block",
    color: props.theme.palette.neutral.darkest,
    "& path": {
      fill: props.theme.palette.neutral.darkest,
    },
  },

  [EBreakPoints.mobile]: {
    width: "100%",
    height: 67,

    padding: `${props.theme.spacings.inset.xs} ${props.theme.spacings.inset.md}`,
  },
}));

export const ListItemInfo = styled.div((props) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  "& div": {
    width: "100%",
    height: "100%",
  },
  "& div:first-of-type": {
    "& p:nth-of-type(1)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.x2s,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.darkest,
      [EBreakPoints.mobile]: {
        fontSize: 10,
      },
    },
    "& p:nth-of-type(2)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
      [EBreakPoints.mobile]: {
        fontSize: props.theme.fontSizes.x2s,
      },
    },
  },

  "& div:nth-of-type(2)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& p:nth-of-type(1)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.x2s,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
      [EBreakPoints.mobile]: {
        fontSize: 10,
      },
    },
    "& p:nth-of-type(2)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.sm,
      color: props.theme.palette.secondary.darker,
      [EBreakPoints.mobile]: {
        fontSize: props.theme.fontSizes.xs,
      },
    },
  },

  "div:has(svg)": {
    display: "flex",
    alignItems: "center",
    width: 24,
    height: 24,
    marginLeft: 16,
  },

  [EBreakPoints.mobile]: {},
}));

export const Button = styled(Chips as StyledComponent<any, any, {}, never>)(
  (props) => ({
    width: 106,
    marginTop: `${props.theme.spacings.stack.nano}`,
    marginBottom: `${props.theme.spacings.stack.nano}`,
    [EBreakPoints.mobile]: {
      width: "82px",
      svg: {
        width: "24px",
        height: "24px",
      },
    },
  }),
);

export const DisplayContent = styled.div((props) => ({
  width: 644,
  height: 664,

  padding: `${props.theme.spacings.stack.x3s} ${props.theme.spacings.stack.x2s}`,

  display: "flex",
  flexDirection: "column",

  backgroundColor: props.theme.palette.neutral.white,

  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.lighter}`,

  [EBreakPoints.mobile]: {
    padding: `0 ${props.theme.spacings.stack.x3s}`,
    width: "100vw",
    border: 0,
  },
}));
