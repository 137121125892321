import { useEffect, useState } from "react";
import {
  appVersionQueryFactory,
  RECEIVED_CDN,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export function useCheckAppVersion() {
  const [route, setRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };
    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return useQuery(
    appVersionQueryFactory.check({
      url: RECEIVED_CDN,
      route,
      storageKey: "RECEIVED_VERSION",
    }) as any,
  );
}
