import { Button } from "@hyperlocal/vital";
import styled, { StyledComponent } from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const Content = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: props.theme.spacings.stack.x3s,
  justifyContent: "space-between",

  "& > .contentWrapper": {
    display: "flex",
    flexDirection: "column",
  },
  ".button": {
    display: "flex",
    justifyContent: "center",
  },

  [EBreakPoints.mobile]: {
    width: "100%",
    padding: "0px",
    border: 0,
    margin: 0,
  },
}));

export const ExpandAllButton = styled.button((props) => ({
  backgroundColor: "transparent",
  border: "none",
  outline: "none",

  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: props.theme.spacings.stack.x2s,

  cursor: "pointer",

  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.default,
    color: props.theme.palette.neutral.darkest,
  },

  "[data-expanded='true']": {
    transform: "rotate(90deg)",
  },
  "[data-expanded='false']": {
    transform: "rotate(0deg)",
  },

  "& div:has(svg)": {
    display: "flex",
    alignItems: "center",
    width: 24,
    height: 24,

    "& svg": {
      transition: "transform 0.3s ease-in-out",
      width: 14.65,
      height: 14.65,
      "& path": {
        fill: props.theme.palette.neutral.darkest,
      },
    },
  },
}));

export const ListContainer = styled.div(() => ({
  flex: 1,
}));

export const List = styled.ul((props) => ({
  height: "100%",
  "-ms-overflow-style": "none",
  "::-webkit-scrollbar": {
    display: "none",
  },

  "& > .listWrapper": {
    height: "100%",
  },

  li: {
    "& + li": {
      marginTop: props.theme.spacings.stack.x3s,
    },
  },
  [EBreakPoints.mobile]: {
    height: "100%",
    width: "100%",
    padding: "0px",
    border: "0",
    margin: "0",

    "& > .listWrapper": {
      flex: 1,
    },
  },
}));

export const GoToTopButton = styled(
  Button as StyledComponent<any, any, {}, never>,
)((props) => ({
  width: "100%",
  marginTop: props.theme.spacings.stack.x2s,

  [EBreakPoints.mobile]: {},
}));
