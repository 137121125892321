import {
  toast,
  useSendNativeEvent,
  UseSendNativeEvent,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useMutation } from "@tanstack/react-query";
import { api, IMutationConfig } from "../lib";

export interface IDocumentBody {
  "Data de processamento": string;
  "Data da transação": string;
  "Valor bruto(R$)": string;
  Parcela: number;
  "Nº de parcelas": number;
  Tipo: null | string;
  Operação: string;
  Destino: string;
  "Taxa da transação(R$)": string;
  "Taxa da antecipação(R$)": string;
  "Valor líquido(R$)": string;
  Bandeira: string;
  NSU: string;
  Terminal: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IDownloadDocumentProps {
  extension: "pdf" | "xlsx";
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
}

export async function downloadDocument({
  extension,
  body,
  name,
  sendMessage,
  isNative,
}: IDownloadDocumentProps & UseSendNativeEvent<any>) {
  try {
    const response = await api.post(`desk/export/${extension}`, body, {
      responseType: "arraybuffer",
      headers: {
        Accept:
          extension === "pdf" ? "application/pdf" : "application/vnd.ms-excel",
      },
    });
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const buffer = await blob.arrayBuffer();
    const base64 = btoa(
      new Uint8Array(buffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        "",
      ),
    );

    if (base64 && isNative) {
      sendMessage("FILE_DOWNLOAD", {
        base64,
        filename: `${name}.${extension}`,
      });
    } else {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `${name}.${extension}`);
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    console.log("🚀 ~ file: downloadDocument.ts:74 ~ error:", error);
    toast.error("Houve um erro ao tentar baixar o documento.");
  }
}

interface IUseDownloadDocumentProps {
  config?: IMutationConfig<typeof downloadDocument>;
}

export function useDownloadDocument(config: IUseDownloadDocumentProps = {}) {
  const { isNative, sendMessage } = useSendNativeEvent();
  return useMutation({
    ...config,
    mutationKey: ["downloadDocument"],
    mutationFn: (data: IDownloadDocumentProps) =>
      downloadDocument({
        ...data,
        sendMessage,
        isNative,
      }),
  });
}
