import { ComponentProps } from "react";
import { Avatar as VitalAvatar } from "@hyperlocal/vital";
import styled from "styled-components";
import { EBreakPoints } from "../../@types";

type AvatarProps = ComponentProps<typeof VitalAvatar>;

export const Avatar: typeof VitalAvatar = styled(VitalAvatar)<AvatarProps>(
  (props) => ({
    backgroundColor: props.theme.palette.primary.lighter,
    cursor: "initial",
    width: 44,
    height: 44,
    marginRight: 16,
    "& svg": {
      width: 17.42,
      height: 17.42,
      "& path": {
        fill: props.theme.palette.primary.main,
      },
      "& g": {
        stroke: props.theme.palette.primary.main,
      },
    },
    [EBreakPoints.mobile]: {
      width: 40,
      height: 40,
    },
  }),
);
