import { ComponentProps } from "react";
import { Avatar as VitalAvatar } from "@hyperlocal/vital";
import styled from "styled-components";
import { EBreakPoints } from "../../@types";

interface IContainerProps {
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  minW?: string | number;
}

export const Container = styled.div<IContainerProps>((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",

  padding: `${props.theme.spacings.inset.md} ${props.theme.spacings.inset.lg}`,

  height: props.height ? props.height : "100%",
  width: props.width ? props.width : "100%",

  maxHeight: 96,
  minHeight: 96,
  maxWidth: 644,
  minWidth: props.minW ? props.minW : "100%",

  borderRadius: props.theme.borderRadius.sm,
  border: `1px solid ${props.theme.palette.neutral.white}`,

  backgroundColor: props.theme.palette.neutral.white,

  [EBreakPoints.mobile]: {
    width: "100%",
    padding: props.padding ? props.padding : props.theme.spacings.inset.md,
    border: 0,
    margin: 0,
    minWidth: "unset",
    maxWidth: "unset",
  },
}));

export const Content = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

export const Info = styled.div((props) => ({
  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.lg,
    color: props.theme.palette.neutral.darkest,
    [EBreakPoints.mobile]: {
      fontSize: props.theme.fontSizes.x3s,
      fontWeight: props.theme.fontWeights.medium,
    },
  },

  span: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.x2s,
    color: props.theme.palette.neutral.dark,
    lineHeight: props.theme.lineHeights.md,
    [EBreakPoints.mobile]: {
      fontWeight: props.theme.fontWeights.medium,
    },
  },
}));

export const Value = styled.div((props) => ({
  display: "flex",
  alignItems: "center",

  h4: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.md,
    lineHeight: props.theme.lineHeights.md,
    textAlign: "right",

    color: props.theme.palette.primary.main,

    paddingBottom: 1,
    paddingLeft: 4,
    [EBreakPoints.mobile]: {
      paddingBottom: 4,
    },
  },
  h6: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.default,
    textAlign: "right",
    color: props.theme.palette.primary.main,
    [EBreakPoints.mobile]: {
      fontSize: 10,
    },
  },
}));

type AvatarProps = ComponentProps<typeof VitalAvatar>;

export const Avatar: typeof VitalAvatar = styled(VitalAvatar)<AvatarProps>(
  (props) => ({
    backgroundColor: props.theme.palette.primary.lighter,

    cursor: "initial",

    width: 44,
    height: 44,

    marginRight: 16,

    "& svg": {
      width: 17.42,
      height: 17.42,
      "& path": {
        fill: props.theme.palette.primary.main,
      },
      "& g": {
        stroke: props.theme.palette.primary.main,
      },
    },
    [EBreakPoints.mobile]: {
      width: 40,
      height: 40,
    },
  }),
);
