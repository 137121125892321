import { ComponentProps } from "react";
import { Button, Avatar as VitalAvatar } from "@hyperlocal/vital";
import styled, { StyledComponent } from "styled-components";
import { EBreakPoints } from "../../../../@types";

export const Container = styled.div((props) => ({
  width: 480,
  height: 1024,

  display: "flex",
  flexDirection: "column",

  backgroundColor: props.theme.palette.neutral.white,

  [EBreakPoints.mobile]: {
    width: "100vw",
    height: "100%",
  },
}));

type AvatarProps = ComponentProps<typeof VitalAvatar>;

export const Avatar: typeof VitalAvatar = styled(VitalAvatar)<AvatarProps>(
  (props) => ({
    backgroundColor: props.theme.palette.primary.lighter,

    cursor: "initial",

    width: 44,
    height: 44,

    marginRight: 16,

    "& svg": {
      width: 17.42,
      height: 17.42,
      "& path": {
        fill: props.theme.palette.primary.main,
      },
      "& g": {
        stroke: props.theme.palette.primary.main,
      },
    },
    [EBreakPoints.mobile]: {
      width: 40,
      height: 40,
    },
  }),
);

export const Content = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: props.theme.spacings.stack.x3s,
  justifyContent: "space-between",

  "& > .contentWrapper": {
    display: "flex",
    flexDirection: "column",
  },
  ".button": {
    display: "flex",
    justifyContent: "center",
  },

  [EBreakPoints.mobile]: {
    width: "100%",
    padding: "0px",
    border: 0,
    margin: 0,
  },
}));

export const ExpandAllButton = styled.button((props) => ({
  backgroundColor: "transparent",
  border: "none",
  outline: "none",

  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: props.theme.spacings.stack.x2s,

  cursor: "pointer",

  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.default,
    color: props.theme.palette.neutral.darkest,
  },

  "[data-expanded='true']": {
    transform: "rotate(90deg)",
  },
  "[data-expanded='false']": {
    transform: "rotate(0deg)",
  },

  "& div:has(svg)": {
    display: "flex",
    alignItems: "center",
    width: 24,
    height: 24,
    "& svg": {
      transition: "transform 0.3s ease-in-out",
      width: 14.65,
      height: 14.65,
      "& path": {
        fill: props.theme.palette.neutral.darkest,
      },
    },
  },
}));

export const GoToTopButton = styled(
  Button as StyledComponent<any, any, {}, never>,
)((props) => ({
  width: "100%",
  marginTop: props.theme.spacings.stack.x2s,

  [EBreakPoints.mobile]: {},
}));

export const ListContainer = styled.div(() => ({
  flex: 1,
}));

export const List = styled.ul((props) => ({
  height: "100%",
  "-ms-overflow-style": "none",
  "::-webkit-scrollbar": {
    display: "none",
  },

  "& > .listWrapper": {
    height: "100%",
  },

  li: {
    "& + li": {
      marginTop: props.theme.spacings.stack.x3s,
    },
  },
  [EBreakPoints.mobile]: {
    height: "100%",
    width: "100%",
    padding: "0px",
    border: "0",
    margin: "0",

    "& > .listWrapper": {
      flex: 1,
    },
  },
}));

export const ListItem = styled.li((props) => ({
  listStyleType: "none",

  padding: `${props.theme.spacings.inset.xs} ${props.theme.spacings.stack.x2s} `,

  transition: "height 0.3s ease-in-out",

  "&[data-expanded='true']": {
    height: 489,
    border: `1px solid ${props.theme.palette.primary.main}`,
  },
  "&[data-expanded='false']": {
    height: 73,
    border: `1px solid ${props.theme.palette.neutral.light}`,
  },
  borderRadius: props.theme.borderRadius.sm,

  [EBreakPoints.mobile]: {
    padding: `${props.theme.spacings.inset.xs} ${props.theme.spacings.stack.x3s} `,
    gap: props.theme.spacings.stack.x3s,

    width: "100%",
  },
}));

export const ListItemHeader = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
  height: "100%",
  maxHeight: 73,

  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    lineHeight: props.theme.lineHeights.default,
  },

  div: {
    width: "100%",
    display: "flex",
    gap: props.theme.spacings.stack.quarck,
    flexDirection: "column",
  },

  "& div:first-of-type": {
    paddingLeft: props.theme.spacings.inline.x2s,
  },

  "& div:nth-of-type(2)": {
    "& p:first-of-type": {
      fontSize: props.theme.fontSizes.x2s,
      lineHeight: props.theme.lineHeights.default,
      color: props.theme.palette.neutral.darkest,
    },
    "& p:last-of-type": {
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.default,
      color: props.theme.palette.neutral.dark,
    },
  },

  "& div:nth-of-type(3)": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    "& p:first-of-type": {
      fontSize: props.theme.fontSizes.x2s,

      color: props.theme.palette.neutral.dark,
    },
    "& p:last-of-type": {
      fontSize: props.theme.fontSizes.xs,
      color: props.theme.palette.secondary.darker,
    },
  },

  "& button": {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: 24,
    height: 24,

    "[data-expanded='true']": {
      transform: "rotate(90deg)",
    },
    "[data-expanded='false']": {
      transform: "rotate(0deg)",
    },

    "& svg": {
      width: 14.65,
      height: 14.65,

      transition: "transform 0.3s ease-in-out",
      "& path": {
        fill: props.theme.palette.neutral.darkest,
      },
    },
  },

  "div:has(svg)": {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",

    width: 32,
    height: 32,

    "& svg": {
      width: 32,
      height: 32,
    },
  },
}));

export const ListItemInfo = styled.div((props) => ({
  width: "100%",

  alignItems: "center",

  transition: "visibility 0.3s ease-in-out",

  "&[data-expanded='true']": {
    visibility: "visible",
  },
  "&[data-expanded='false']": {
    visibility: "hidden",
  },

  "& div + div": {
    marginTop: props.theme.spacings.stack.nano,
  },

  "& div:last-of-type": {
    "& p:first-of-type": {
      fontFamily: props.theme.fonts.base,
      fontSize: props.theme.fontSizes.x2s,
      fontWeight: props.theme.fontWeights.medium,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.darkest,
      textAlign: "left",
    },

    "& p:last-of-type": {
      fontFamily: props.theme.fonts.base,
      fontSize: props.theme.fontSizes.x2s,
      fontWeight: props.theme.fontWeights.regular,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
    },
  },
}));

export const ListItemInfoRow = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  "& div + div": {
    marginTop: props.theme.spacings.stack.nano,
  },

  "& p:first-of-type": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.x2s,
    fontWeight: props.theme.fontWeights.medium,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
    textAlign: "left",
  },

  "& p:last-of-type": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.x2s,
    fontWeight: props.theme.fontWeights.regular,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.dark,
  },
}));
