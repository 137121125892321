import React, { PropsWithChildren, useCallback, useRef } from "react";
import {
  useDisableBodyScroll,
  useEventListener,
  useOnClickOutside,
} from "../../hooks";
import * as Styles from "./Modal.styles";
import { Portal } from "./Portal";
import { ISize } from "./types";

export interface IModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  size?: ISize | "full";
}

interface IHeaderProps extends PropsWithChildren {
  onClose: () => void;
  size?: "sm" | "md" | "lg";
}

function Header({ children, onClose, size = "sm" }: IHeaderProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <Styles.Header size={size}>
      <h6>{children}</h6>
      <Styles.CloseButton
        ref={buttonRef}
        onClick={onClose}
        aria-label="Close modal"
      >
        <Styles.CloseIcon name="SettingTrashClose" />
      </Styles.CloseButton>
    </Styles.Header>
  );
}

export function Modal({
  isOpen,
  onClose,
  size = "full",
  children,
}: IModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const visibility = isOpen ? "open" : "closed";

  const handleClickOutside = () => onClose();

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") onClose();
    },
    [onClose],
  );

  useOnClickOutside(modalRef, handleClickOutside);
  useEventListener("keydown", handleKeyPress, buttonRef);
  useDisableBodyScroll(isOpen);

  return (
    <Portal wrapperId="modal">
      <Styles.Overlay data-state={visibility}>
        <Styles.Container
          data-state={visibility}
          ref={modalRef}
          role="dialog"
          size={size}
        >
          {children}
        </Styles.Container>
      </Styles.Overlay>
    </Portal>
  );
}

Modal.Header = Header;
Modal.Content = Styles.Content;
Modal.Footer = Styles.Footer;
Modal.Button = Styles.Button;
