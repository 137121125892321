import { format, getDay, isSameDay, parse, startOfWeek } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  dateFnsLocalizer,
  Event,
  Calendar as ReactBigCalendar,
  SlotInfo,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { isDateBetween } from "../../../../utils/date/isDateBetween";
import * as S from "./styles";
import { eventPropGetter, weekDayFormat } from "./utils";

const locales = {
  "pt-BR": {
    ...ptBR,
  },
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export type CalendarEvents = Event[];

export type CalendarProps = {
  date: Date;
  events: CalendarEvents;

  selectionMode: "SINGLE" | "RANGE";
  onSelectRange?: (range: { start: Date; end: Date }) => void;
  selectedRange: { start: Date; end: Date } | null;
};

export const Calendar = (props: CalendarProps) => {
  const {
    date,
    events,
    selectionMode,
    selectedRange,
    onSelectRange,
    ...reactBigCalendarProps
  } = props;

  const handleSelectRange = (slotInfo: SlotInfo) => {
    if (selectionMode === "RANGE" && onSelectRange) {
      const { slots } = slotInfo;

      onSelectRange({ start: slots[0], end: slots[slots.length - 1] });
    }
  };

  const handleSelectEvent = (event: Event) => {
    const { start, end } = event;

    onSelectRange({ start, end });
  };

  return (
    <S.CalendarWrapper className="rbc-wrapper">
      {/* @ts-ignore */}
      <ReactBigCalendar
        toolbar={false}
        startAccessor="start"
        endAccessor="end"
        culture="pt-BR"
        defaultDate={date}
        localizer={localizer}
        events={events}
        formats={{
          weekdayFormat: weekDayFormat,
        }}
        eventPropGetter={eventPropGetter}
        onSelectSlot={handleSelectRange}
        onSelectEvent={handleSelectEvent}
        dayPropGetter={(date: Date) => {
          if (selectedRange) {
            const { end, start } = selectedRange;

            if (
              isSameDay(date, selectedRange.start) ||
              isSameDay(date, selectedRange.end)
            ) {
              return { className: "rbc-day-selected" };
            }

            const inRange = isDateBetween(date, start, end);

            if (inRange) {
              return { className: "rbc-day-in-range" };
            }
          }
        }}
        selectable={selectionMode === "RANGE"}
        {...reactBigCalendarProps}
      />
    </S.CalendarWrapper>
  );
};
